<template>
  <iframe height="100%" width="100%" :src="$route.meta.src" frameborder="0" ></iframe>
</template>

<script>
export default {
  name: 'Index',
  created () {
    console.log(this.$route, 'route')
  }
}
</script>

<style scoped>
html,body,iframe
 {
  display: block; /* iframes are inline by default */
  background: #000;
  border: none; /* Reset default border */
  height: 100vh; /* Viewport-relative units */
  width: 100%;
  position:absolute;
}
</style>
